import live from '../helpers/live.js'

function handleUjsForm(event) {
  if (event.detail[0].nodeType == 9) {
    document.body.innerHTML = event.detail[0].body.innerHTML
  } else if (event.detail[2].status >= 400) {
    location.reload(true)
  }
}

live('form[data-remote=true]', 'ajax:success', handleUjsForm)
live('form[data-remote=true]', 'ajax:error', handleUjsForm)