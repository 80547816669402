import { Controller } from 'stimulus'

let escapeTimeout, growTimeout, buttonClassName = 'payscreen__escape-button'

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.buttonTarget.addEventListener(holdStartEvent(), (event) => {
      growTimeout = setTimeout(() => {
        this.buttonTarget.classList.add(`${buttonClassName}--grown`)
      }, 1000)

      escapeTimeout = setTimeout(() => {
        location.href = this.data.get('escapePath')
        this.buttonTarget.classList.add(`${buttonClassName}--blown`)
      }, 3000)
    })
    this.buttonTarget.addEventListener(holdEndEvent(), (event) => {
      clearTimeout(growTimeout)
      clearTimeout(escapeTimeout)

      this.buttonTarget.classList.remove(`${buttonClassName}--grown`)
    })
  }
}

function holdStartEvent() {
  return hasTouchStartEvent() ? 'touchstart' : 'mousedown'
}

function holdEndEvent() {
  return hasTouchStartEvent() ? 'touchend' : 'mouseup'
}

function hasTouchStartEvent() {
  return 'ontouchstart' in document.createElement('div')
}