import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['name', 'handle']

  connect() {
    this.active = this.data.get('active') == 'true'
  }

  generate() {
    if (this.active)
      this.handleTarget.value = toKebabCase(this.nameTarget.value)
  }

  deactivate() {
    this.active = this.handleTarget.value == ''
  }
}

function toKebabCase(string) {
  return string && string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')
}