import consumer from './consumer'
import CableReady from 'cable_ready'

const terminalMetaTag = document.querySelector('meta[name=current-terminal]')

if (terminalMetaTag) {
  const terminalHandle = terminalMetaTag.getAttribute('content')

  consumer.subscriptions.create({
    channel: 'TerminalChannel',
    handle: terminalHandle
  }, {
    connected() {
      console.log(`Terminal ${terminalHandle} connected`)
    },

    received(data) {
      if (data.cableReady) CableReady.perform(data.operations)
    }
  })
}
