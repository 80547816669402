import { Controller } from 'stimulus'

let timeout

export default class extends Controller {
  connect() {
    timeout = setTimeout(showGreeter, 10 * 1000)
  }

  disconnect() {
    if (timeout) clearTimeout(timeout)
  }
}

function showGreeter() {
  fetch(location.href)
    .then(response => response.text())
    .then(html => (new DOMParser).parseFromString(html, 'text/html'))
    .then(dom => dom.querySelector('[data-behavior=payscreen-display]'))
    .then(updateDisplay)
}

function updateDisplay(display) {
  let element = document.querySelector('[data-behavior=payscreen-display]')
  element.setAttribute('data-state', display.getAttribute('data-state'))
  element.innerHTML = display.innerHTML
}